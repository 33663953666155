import {
  AuthenticatedTemplate,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Browser } from "@syncfusion/ej2-base";
import "bootstrap/dist/css/bootstrap.min.css";
import "file-icon-vectors/dist/file-icon-vivid.min.css";
import { updateLocale } from "moment";
//import 'mdbreact/dist/css/mdb.css';
import React, { PropsWithChildren, useEffect } from "react";
//import 'font-awesome/css/font-awesome.min.css'
import "reflect-metadata";
import "./App.css";
//import UserFeedsContextProvider from './components/shared/UserFeedsContextProvider';
import { handleScroll } from "./helpers/ACutils";
//import LoginPage from './pages/LoginPage';
import { RAFAuthModule, loginRequest } from "./RAFAzure/AuthModule";
import RAFToastComponent from "./RAFComponents/Utility/RAFToastComponent";
import {
  Logout,
  getSessionStorage,
  setSessionStorage,
} from "./RAFComponents/helpers/AppHelper";
import "./RAFMaster/RMModules/RAFPage/RAFPageBuilder/RAFPageBuilderStyles.css";
import "./RAFMaster/styles/RMStyles.scss";
import "./styles/ContainerStyle.scss";
import "./styles/EntityStyles.scss";
import "./styles/FontStyle.scss";
import "./styles/GeneralStyleSheet.scss";
//import "./UI/Inputs/inputs.scss";
import { InteractionType } from "@azure/msal-browser";
import ACLoadingPanel from "./components/shared/ACLoadingPanel";
import { StorageKey } from "./constants/Common/Constants";
import "./styles/DialogStyle.scss";
import "./styles/GridStyle.scss";
import "./styles/button_style.scss";
import "./styles/sky_button_style.scss";
import "./styles/constant_content_style.scss";
import "./styles/constant_surface_style.scss";
import "./styles/font_family.scss";
import "./styles/general_bem_style.scss";
import "./styles/main.scss";
import "./styles/mainBEMStyle.scss";
import "./styles/mainNewStyle.scss";
import "./styles/tabStyle.scss";
// import "./styles/treeviewStyle.scss";
import AppAuthenticated from "./AppAuthenticated";
import skyloading2 from "./assets/ACLoadingIcon.svg";
import { AppUnAuthenticated } from "./router/AppUnAuthenticated";
import { isNotNullAndUndefined } from "./RAFComponents/helpers/utils";
import AccountSelection from "./AccountSelection";

updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    ss: "%ss",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    M: "a month",
    MM: "%dM",
    y: "a year",
    yy: "%dY",
  },
});

interface IProps {
  //extends RouteComponentProps {
  pca: RAFAuthModule;
}

function App({ pca, ...props }: PropsWithChildren<IProps>) {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    if (Browser.isDevice) {
      document.body.classList.add("e-bigger");
    }
    window.addEventListener("scroll", handleScroll);
    const ShowSideMenu2 = getSessionStorage(StorageKey.ShowSideMenu2, true);
    setSessionStorage(StorageKey.ShowSideMenu2, true, true);
    setSessionStorage(StorageKey.ShowSideMenu, true, true);
  });
  async function checkScrollable() {
    const iframe = document.querySelector("iframe");
    if (isNotNullAndUndefined(iframe) && iframe.style.visibility === "hidden") {
      iframe.classList.add("iframe-hidden");
    } else if (isNotNullAndUndefined(iframe)) {
      iframe.classList.remove("iframe-hidden");
    }
  }
  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  const IsLogoutPage = (): boolean => {
    let retVal = false;
    const windowLocationPathname = window.location.pathname.toLowerCase();
    switch (windowLocationPathname) {
      case "/logout":
      case "/logout/":
        retVal = true;
        break;
      default:
        break;
    }
    return retVal;
  };

  const Loading = () => {
    return <ACLoadingPanel loadingText="Authentication in progress..." />;
  };

  const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
    useEffect(() => {
      if (error) {
        if (
          error.errorCode === "redirect_uri_mismatch" ||
          error.errorCode === "authority_mismatch"
        ) {
          // Clear storage and log out on specific error codes
          localStorage.clear();
          sessionStorage.clear();
          Logout();
        }
      }
    }, [error]);

    if (!error) return null;

    return (
      <div>
        {error.errorCode === "redirect_uri_mismatch" ||
          error.errorCode === "authority_mismatch" ? (
          <div className="d-flex flex-column align-items-center">
            <img src={skyloading2} width="100px" alt="Loading" />
            <span className="loadingText text-center">
              Session Expired.
              <br />
              We are trying to log you in again.
            </span>
          </div>
        ) : (
          <div>An Error Occurred: {error.errorCode}</div>
        )}
      </div>
    );
  };

  const isLogoutPage: boolean = IsLogoutPage();
  const authRequest = { ...loginRequest };

  return (
    <MsalProvider instance={pca}>
      {/* <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loading}
            > */}
      <div id="sidebar-content" className="h-100 sidebar-content ms-0">
        <div className="p-0 container-fluid">
          <div className={"h-100"}>
            {!isLogoutPage && (
              <AuthenticatedTemplate>
                {/* <AccountSelection msalInstance={pca} /> */}
                <AppAuthenticated />
              </AuthenticatedTemplate>
            )}
            {isLogoutPage && <AppUnAuthenticated />}
            <UnauthenticatedTemplate>
              <AppUnAuthenticated />
            </UnauthenticatedTemplate>
          </div>
        </div>
        <RAFToastComponent />
      </div>
      {/* </MsalAuthenticationTemplate> */}
    </MsalProvider>
  );
}

export default App;
